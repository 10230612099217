// vue
import { ref, computed, onMounted, reactive } from 'vue';

// services
import BotsService from '@services/bots';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute } from 'vue-router'; 

// store
import { useGl } from '@store/ts/gl';
import { useTools } from '@store/tools';

// naive-ui
import {
    useNotification } from 'naive-ui';

// components
import ConfigPresets from '../childs/config-presets';
import FiltersPresets from '../childs/filters-presets';
import OrderMatrixPresets from '../childs/order-matrix-presets';

export default function (props, context) {
    // store
    const gl = useGl();
    const tools = useTools();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // router
    const route = useRoute();

    // vars
    const presets = ref();
    const loading = ref(false);
    const currentTab = ref(route.params?.tab || 'config-presets');

    const tabs = computed(() => [
        {
            name: 'config-presets',
            component: ConfigPresets,
            title: tools.localization?.tools_presets_config_presets_label || 'Шаболны настроек бота',
        }, {
            name: 'order-matrix-presets',
            component: OrderMatrixPresets,
            title: tools.localization?.tools_presets_order_matrix_presets_label || 'Шаблоны сетки ордеров',
        }, {
            name: 'start-filters-presets',
            component: FiltersPresets,
            title: tools.localization?.tools_presets_filters_presets_label || 'Шаблоны фильтров',
        },
    ]);

    const getData = async () => {
        loading.value = true;

        try {
            presets.value = ( await BotsService.getRefs() ).data;

            configPresetsPrepare();
            orderMatrixPresetsPrepare();
            filterPresetsPrepare();

        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loading.value = false;
    };

    // bots presets
    const configPresetsPrepare = () => {
        const result = [];

        for (const [key, value] of Object.entries(presets.value.presets)) {
            if (value?.ref_id && value.type === 1) {
                result.push({
                    name: key,
                    loading: false,
                    checked: false,
                    ...value,
                });
            }
        }

        tools.configPresets = result;
    };

    // order matrix presets
    const orderMatrixPresetsPrepare = () => {
        const result = [];

        presets.value.orders_matrix.forEach(el => {
            if (el.ref_id && el.type === 3) {
                result.push({
                    loading: false,
                    checked: false,
                    ...el,
                });
            }
        });

        tools.orderMatrixPresets = result;
    };

    // order matrix presets
    const filterPresetsPrepare = () => {
        const result = [];

        presets.value.filter_presets.forEach(el => {
            if (el.ref_id && el.type === 9) {
                result.push({
                    loading: false,
                    checked: false,
                    ...el,
                });
            }
        });

        tools.filterPresets = result;
    };

    onMounted(() => {
        getData();
    });

    return {
        gl,
        tabs,
        tools,
        loading,
        currentTab,
    };
}