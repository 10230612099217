// vue
import { ref, computed, onMounted, reactive } from 'vue';

// services
import BotsService from '@services/bots';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useTools } from '@store/tools';

// naive-ui
import {
    useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const tools = useTools();
  
    // ui
    const notification = useNotification();
  
    // i18n
    const { t } = useI18n();

    const canDeleteSomeOrderMatrixPreset = computed(() => tools.orderMatrixPresets.some(({ checked }) => checked));

    const onDeleteOrderMatrixPreset = async id => {
        const i = tools.orderMatrixPresets.findIndex(el => el.id === id);

        tools.orderMatrixPresets[i].loading = true;

        try {
            const prepare = await BotsService.deleteOrderMatrix({ id });

            if (prepare.data?.status) {
                tools.orderMatrixPresets = tools.orderMatrixPresets.filter(el => el.id != id );
            }

            // show messages
            prepare.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        tools.orderMatrixPresets[i].loading = false;
    };

    const onDeleteSomeOrderMatrixPreset = async () => {
        const ids = tools.orderMatrixPresets.filter(({ checked }) => checked).map(({ id }) => id);

        ids.forEach(id => {
            onDeleteOrderMatrixPreset(id);
        });
    };

    return {
        gl,
        tools,
        canDeleteSomeOrderMatrixPreset,
        onDeleteOrderMatrixPreset,
        onDeleteSomeOrderMatrixPreset,
    };
}